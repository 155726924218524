.container-config {
  display: flex;
  justify-content: space-between;
}

.custom-file-img input[type="file"] {
  display: none;
}

.store-logo {
  text-align: center;
  width: 100px;
  overflow: hidden;
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
}

.store-logo img {
  width: 100%;
}

.container-config .card:nth-child(1) div {
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  height: 200px;
}

.container-config .card:nth-child(1) div span {
  margin: 10px 0;
  font-size: 14px;
}

.container-config .card:nth-child(2) div form {
  display: flex;
  flex-direction: column;
}

form input {
  background-color: #323a4e;
  border: 1px solid #5e6882;
  color: #fff;
  border-radius: 4px;
  height: 40px;
  padding: 0 8px;
  font-size: 16px;
  width: 100%;
}

.form-config {
  max-width: 600px;
  margin: 0 auto;
}

.table-banks {
  min-height: 815px !important;
}

select {
  background-color: #323a4e;
  border: 1px solid #5e6882;
  color: #fff;
  border-radius: 4px;
  height: 40px;
  padding: 0 8px;
  font-size: 16px;
}

form textarea {
  background-color: #323a4e;
  border: 1px solid #5e6882;
  color: #fff;
  border-radius: 4px;
  height: 70px;
  padding: 4px 8px;
  font-size: 16px;
}

form button {
  margin: 16px 0;
  height: 40px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  background-color: #4cb654;
}
