@media only screen and (max-width: 412px) {
    .card {
        margin: 0 10px;
    }
    #cardLogoTexto {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    #texto {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
