.pagesAuth body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  /* font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #212529; 
  text-align: left;
  background-color: #fff;
}

.pagesAuth.accountbg {
  background: linear-gradient(to right, #0C0B0B, #49230F);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.pagesAuth.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

.pagesAuth.wrapper-page {
  margin: 4.5% auto;
  max-width: 460px;
  position: relative;
}

.pagesAuth.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.pagesAuth.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.pagesAuth.form-group {
  margin-bottom: 1rem;
}

.pagesAuth.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.pagesAuth.font-14{
  font-size: 14px;
}

.pagesAuth.form-control1 {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.pagesAuth.form-control1:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.pagesAuth select{
  background-color: #fff;
  color: #000;
}
.form-control1.is-invalid, .was-validated .form-control1:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' hei…circle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e); */
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}