.config-frete {
  display: flex;
  justify-content: flex-start;
}

.frete-area {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-left: 100px;
}
.frete-area div {
  border-radius: 4px;
}

.card-frete {
  background-color: #2a3142;
  padding: 0;
  height: fit-content;
}
.first-input {
  margin-bottom: 15px;
}
.card-frete div:nth-child(1) {
  width: 100%;
  height: auto;
}
.card-frete div img {
  width: 100%;
}
.card-frete div:nth-child(2) {
  padding: 16px;
}

.custom-file {
  width: 100%;
}
.custom-file label {
  background-color: #323a4e;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.07);
}

.custom-file-label::after {
  content: "Selecione o arquivo";
  background-color: #6c757d;
  color: #fff;
}

@media (max-width: 1300px) {
  .frete-area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.content {
  float: right;
}
.switch {
  display: inline-block;
}
.switch input {
  display: none;
}
.switch small {
  display: inline-block;
  width: 60px;
  height: 30px;
  background: #455a64;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}
.switch small:after {
  content: "No";
  position: absolute;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  left: 22px;
  top: 6px;
  text-align: left;
  padding: 0 6px;
  box-sizing: border-box;
  line-height: 18px;
}
.switch small:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  transition: 0.3s;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
}
.switch input:checked ~ small {
  background: #58db83;
  transition: 0.3s;
}
.switch input:checked ~ small:before {
  transform: translate(25px, 0px);
  transition: 0.3s;
  left: 9px;
}
.switch input:checked ~ small:after {
  content: "Yes";
  text-align: left;
  left: 2px;
  font-size: 14px;
}

.switch-disabled small:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  background: darkgrey;
}

.switch-disabled small:after {
  content: "No";
  position: absolute;
  color: darkgrey;
}

.hidden-check1 {
  display: none;
}

.hidden-check2 {
  visibility: hidden;
}
