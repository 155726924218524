/* Chrome, Safari, Edge, Opera */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9e9e9e;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #ccc;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #ccc;
}

table td,
th {
  align-self: center;
}

table td img {
  width: 100%;
  max-width: 170px;
}

.no-photo {
  width: 100%;
  max-width: 170px;

  padding: 3rem 0;
  display: flex;
  background: #ccc;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 25px;
}

.btns-nav button {
  width: 30px;
  height: 30px;

  color: #fff;
  border: 0;
}

.btns-nav button:first-child {
  background: none;
}

.btns-nav button:last-child {
  background: none;
}

.processing {
  background: #f39c12;
}

.shipped {
  background: #e67e22;
}

.completed {
  background: var(--success);
}

.canceled {
  background: var(--danger);
}

.billed {
  background: #16a085;
}

.awaiting_confirmation {
  background: #16a085;
}

.pending {
  background: var(--primary);
}

.pending_payment {
  background: #237c8b;
  color: #fff;
}

.unavailable{
  background: var(--danger);
}

.ready_to_delivery{
  background:#8e44ad;
}
.delivered{
  background:#27ae60;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: none;
  border-color: none;

  opacity: 0.3;
}

/* Firefox */
.no-arrows[type="number"] {
  -moz-appearance: textfield;
}
