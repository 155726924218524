.search-modal {
  background-color: #7a6fbe;
  height: 70px;
}
.search-modal div {
  align-self: center;
}

.custom-file {
  width: 100%;
}
.custom-file label {
  background-color: #323a4e;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.07);
}

/* .custom-file-label::after {
  content: "Selecione o arquivo";
  background-color: #6c757d;
  color: #fff;
} */
.search-modal div input {
  width: 700px;
  min-width: 300px;

  border: 2px solid #aea4eb;
  background-color: #948bca;
  color: #fff;
  border-radius: 100px;
  padding: 10px 14px;
}
.search-modal div input::placeholder {
  color: #fff;
}

div.mx-auto {
  position: relative;
}
.search-modal div i {
  position: absolute;
  right: 8px;
  bottom: 8px;
  font-size: 30px;
  cursor: pointer;
}

.container.products {
  display: flex;
  flex-direction: column;
}
.container.products div {
  display: flex;
  justify-content: space-between;
  background-color: #2a3142;
  margin-bottom: 8px;
  height: auto;
  padding: 16px 4px;
}
.container.products div span {
  margin: 0 auto;
}
.container.products div span button {
  background-color: #ff8031;
  border: none;
  border-radius: 8px;
  width: 100px;
  height: 30px;
  color: #fff;
  text-transform: lowercase;
  font-weight: 600;
}
.container.products div span:nth-child(1) {
  width: 50px;
}
.container.products div span,
.title {
  align-self: center;
}
.container.products div span img {
  width: 100%;
  border-radius: 100px;
}

.container.edit-offers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.table-bordered img {
  max-width: 185px;
}

.custom-control-label:before {
  border-color: #fff;
}

.input-group {
  align-items: center;
}

.btn-orange {
  height: 32.1px;
}

.box-price {
  max-width: 200px;
  align-items: center;
}

.box-price input {
  width: 100%;
}

.processing-input {
  width: 80px;
}

@media (max-width: 990px) {
  .container.edit-offers {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container.edit-offers div {
    margin-bottom: 8px;
  }
  .edit-img {
    width: 200px !important;
  }
}

.edit-img {
  width: 300px;
  text-align: center;
}
.edit-img img {
  max-width: 200px;
  max-height: 200px;
  margin-right: 1rem;
}

.edit-infos div {
  margin-bottom: 8px;
}

.edit-names {
  display: flex;
  flex-direction: column;
}
.edit-names div {
  margin-bottom: 8px;
  font-size: 16px;
}
.edit-names div a {
  color: #ff8031;
  text-decoration: underline;
}

.edit-numbers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  text-align: center;
}

.counter-products {
  display: flex;
  justify-content: center;
}
.counter-products div:nth-child(1) {
  background-color: #ff8031;
  width: 40px;
  height: 30px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.counter-products div:nth-child(1) i {
  font-size: 10px;
  vertical-align: middle;
}

.counter-products div:nth-child(2) input {
  width: 80px;
  height: 30px;
  text-align: center;
  border: none;
  background-color: #4c515c;
  color: #fff;
}
.counter-products div:nth-child(3) {
  background-color: #ff8031;
  width: 40px;
  height: 30px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.counter-products div:nth-child(3) i {
  font-size: 10px;
  vertical-align: middle;
}

.white-no {
  white-space: initial !important;
}

.edit-numbers div:nth-child(2) div input {
  border: none;
  background-color: #4c515c;
  height: 30px;
  border-radius: 4px;
  padding-right: 4px;
  padding-left: 4px;
  color: #fff;
}

.box-out{
  padding: 10px;
}
.box-in{
  background-color: #323a4e;
  border-radius: 10px;
  padding: 35px;
}
.txt-produto{
  font-size: 18px;
  margin-bottom: 20px;
}
.btn-new{
  width: 200px;
  font-size: 13px;
  text-align: initial;
  padding: 5px 16px;
  padding-right: 17px;
  border-radius: 5px;
  line-height: 18px;
}
.large{
  padding-right: 10px;
}
.btdl{
  margin-top: 15px;
}
.espaco{
  height: 50px;
  padding: 25px 70px;
  margin: 29px 0px;
}
.linha{
  height: 2px;
  background-color: #6c757d;
}
.uploading{
  padding-top: 10px;
}

.esconder{
	display: none;
}
.mostrar{
	display: block;
}
@media (max-width: 1366px) {
  .espaco{
    margin: 5px 0px;
  }
}
.cinza{
  background-color: #6c757d!important;
  padding-right: 15px;
}
