.loader {
  position: absolute;  
  top: 45%;
  left: 45%;
  height: 60px;
  width: 60px;
  z-index: 2;
}

.bg-transparente {
  background: rgba(255, 255, 255, 0.2);
}