.special-tools button {
  background: none;
  border: 0;
}

.special-tools button:hover {
  background: none;
  border: 0;

  color: #f27427;
}

.special-tools focus:hover {
  background: none;
  border: 0;

  color: #f27427;
}

.special-tools active:hover {
  background: none;
  border: 0;

  color: #f27427;
}

#navigation img {
  width: 150px;
}

.special-tools i {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
  text-align: center;
  transition: all 0.5s ease;
}

.special-tools:hover {
  color: #f27427;
}
